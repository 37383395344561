import React from 'react';
import { P, Root } from './styles';
import sanitize from '../../utils/sanitizer';

const NA = ({ msg, dir }) => {
  return (
    <Root>
      <P dir={dir} dangerouslySetInnerHTML={{ __html: sanitize(msg) }}></P>
    </Root>
  );
};

export default NA;
