import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import { Root, Header, P } from './styles';
import c from '../../utils/constants';
import sanitize from '../../utils/sanitizer';

const Success = (props) => {
  return (
    <Root>
      <Header>
        <FontAwesomeIcon color={c.success} icon={faCheck} />
      </Header>
      <P dangerouslySetInnerHTML={{__html: sanitize(props.text) }}></P>
    </Root>
  );
};
export default Success;
