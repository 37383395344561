import config from '../config';
export default {
  lang: `Ar`,
  begin: `إبدأ`,
  enterPGN: `أدخل رقم الملف`,
  enterPhone: `أدخل رقم هاتفك الخليوي`,
  samePhone: `هل هذا رقم هاتفك الحالي؟`,
  heardAbout: `من أين سمعت عن هذه العملية (آلية تقديم الطلبات)؟`,
  enterOTP: `ادخل الرقم السري الذي وصلك في الرسالة (${config.otp} أرقام)`,
  submit: `أدخل`,
  stillHaveCard: `هل لا زلت تملك البطاقة؟`,
  submitAnotherOTP: `أرسل الرقم مرة أخرى`,
  stillHavePin: `هل لا زلت تملك الرقم السري؟`,
  yes: `نعم`,
  no: `كلا`,

  error: `خطأً`,
  nvn: `لا حاجة للتدقيق`,
  cd: `الأرقام صحيحة`,
  wd: `الأرقام خاطئة`,
  ta: `محاولات خاطئة متكررة`,
  id: `الأرقام غير صحيحة`,
  cv: `لقد تم التحقق من البطاقة`,
  wp: `رقم الملف غير صحيح`,
  or: `الرمز السري`,
  ip: `رقم الهاتف غير صحيح`,
  wo: `الرمز غير صحيح`,
  nc: `لست مخول`,
  ng: `لم تختر أي شيء`,
  nd: `غير ممكن`,
  to: `تخطيت عدد المحاولات المسموح بها`,
  captcha: `الرمز الأمني غير صحيح`,
  captchaDesc: `الرجاء إدخال الرمز الأمني`,
  captcha2: `خطأ في التحقق`,
  captchaDesc2: `لم نستطع التحقق من أنك لست روبوت`,
  toDesc: `لقد تخطيت عدد المحاولات المسموح بها يرجى الاتصال على <a href="tel:+9614726111" dir="LTR">04 726 111</a> من الإثنين إلى الجمعة لتقديم طلب بالاضافة  `,
  ndDesc: `تم تسجيل طلبك سابقاً، ولست بحاجة إلى تقديم طلب آخر. يرجى الملاحظة:
  <ul>
  <li>لست بحاجة لإعادة تسجيل طلب مرة أخرى لدى مراكز الاتصال</li>
  <li>إذا كنت ستتلقى المساعدة الغذائيّة أو المساعدة الماليّة ابتداءً من كانون الأول 2023، فلن تكون مؤهلاً لدراسة طلبك.</li>
  <li>ستتلقى رسالة نصيّة في شباط 2024 لإبلاغك بنتيجة طلبك</li>
  </ul>
  `,
  ngDesc: `عليك إختيار واحدة من الخيارات`,
  ncDesc: `أنت لست مخول لاستعمال هذه الخاصية`,
  woDesc: `لقد ادخلت رمزاً خاطئاً`,
  ipDesc: `لقد ادخلت رقماً غير صحيح`,
  orDesc: `سوف تصلك رسالة قصيرة تحتوي على رمز يتألف من ${config.otp} أرقام، أدخلهم في المربع و إضغط على <b>أدخل</b>`,
  wpDesc: `لقد ادخلت رقم ملف غير صحيح`,
  cvDesc: `لقد تم التحقق من بطاقتك`,
  idDesc: `أدخل ${config.digits} أرقام فقط`,
  nvnDesc: `لست بحاجة لإتمام عملية التدقيق`,
  cdDesc: `لقد ادخلت رمزاً صحيحاً`,
  wdDesc: `لقد ادخلت رقماً غير صحيح، تأكد أنك تدخل آخر ${config.digits} أرقام من ناحية اليمين `,
  taDesc: `لقد ادخلت ${config.attempts} محاولات خاطئة، ولم يعد بإمكانك المحاولة `,
  errDesc: `لقد حصل خطأً، يرجى المحاولة لاحقاً`,
  ok: `حسناً`,
  dir: `RTL`,
  verComp: `هذه نهاية العملية. تشكرك المفوضيّة على تعاونك وستتواصل معك من خلال رسالة نصيّة أو مكالمة هاتفيّة إذا كانت هناك حاجة لإبلاغك بأي مستجدات`,
  tryAgain: `جرب مجدداً`,
  remainingAttemps: `المحاولات المتبقية: `,
  unhcrWillContact: `سوف تقوم المفوضية بالإتصال بك خلال بضعة أيام`,
  noCard: `شكراً لتكملة العملية وللتأكيد أنك لا تملك البطاقة الحمراء. ستتواصل معك المفوضيّة من خلال رسالة نصيّة أو مكالمة هاتفيّة إذا كانت هناك حاجة لإبلاغك بأي مستجدات.`,
  thankYou: `شكراً لك ، تم تسجيل طلبك. يرجى الملاحظة:
  <ul>
  <li>لست بحاجة لإعادة تسجيل طلب مرة أخرى لدى مراكز الاتصال. لن يؤدي تقديم أكثر من طلب إلى زيادة فرصك في إعادة النظر بملفك</li>
  <li>لقد تلقينا طلبك. سيتم إعادة النظر في عدد قليل فقط من العائلات للحصول على المساعدة، وستُعطى الأولوية للأسر التي لا تتلقى أي نوع من المساعدة. ستتلقى رسالة نصيّة في شباط 2024 لإبلاغك بنتيجة طلبك</li>
  </ul>`,
  fail: `للأسف، لا يمكننا تقديم طلبك عبر الرابط، يرجى الاتصال بمركز الاتصال التابع للمفوضيّة على <a href="tel:+9614726111" dir="LTR">04 726 111</a> من الاثنين إلى الجمعة (من 8 صباحاً حتى 5 مساءً)، مركز الإتصال التابع لبرنامج الأغذية العالمي على <a href="tel:1526" dir="LTR">1526</a> من الإثنين إلى السبت (من 8 صباحاً حتى 7 مساءً) لتقديم طلب ولتحديث رقم هاتفك.`,
  intro: `
  يمكنك تقديم طلب لإعادة النظر بأهليتك للحصول على المساعدة الماليّة أو الغذائية الشهرية من قبل المفوضيّة وبرنامج الأغذية العالمي.
  <br/>
  <br/>
  يجب تقديم جميع الطلبات بين 30 تشرين الأول و11 كانون الأول 2023. بعد هذا التاريخ، سيتم دراسة معلوماتك من قبل المفوضيّة وبرنامج الأغذية العالمي وسيتم إضافة عدد قليل من العائلات للحصول على المساعدة.
  <br/>
  <br/>
  يرجى اتباع الخطوات لتسجيل الطلب. إذا كنت قد قدمت طلب من خلال مراكز الاتصال، فلن تحتاج إلى تقديم طلب آخر هنا.`,
  submitToLog: `إضغط على أدخل لإدخال طلبك إلى مفوضية الأمم المتحدة السامية لشؤون اللاجئين وبرنامج الأغذية العالمي`,
  note: `ملاحظة: يتم جمع هذه المعلومات بهدف التحسين فحسب`,
  na: `لقد تم  إغلاق آلية تقديم الطلبات لإعادة النظر بأهلية العائلات الغير مختارة للمساعدة  لعام 2023/2024 في 11 كانون الأول 2023.  ستبلغك المفوضيّة وبرنامج الأغذية العالمي بنتيجة الطلب في شباط 2024. للإستفسار، يرجى الاتصال بالمفوضيّة على <a href="tel:04726111">04 726 111</a> أو برنامج الأغذية العالمي على <a href="tel:1526">1526</a>.`,
};
