import React from 'react';

import { UNHCRLogo, WFPLogo, DIV } from './styles';

import wfpLogo from '../../_images/wfp-logo.png';
import unhcrLogo from '../../_images/unhcr-logo.png';

const HeaderLogo = (props) => {
    return(
    <DIV width={props.width}>
        <UNHCRLogo src={unhcrLogo} />
        <WFPLogo src={wfpLogo} />
    </DIV>);
}

export default HeaderLogo;