import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import c from '../../utils/constants';
import sanitize from '../../utils/sanitizer';
import 'bootstrap/dist/css/bootstrap.min.css';
import LocalizedStrings from 'react-localization';
import stringsToLocalize from '../../localisation';

import Error from '../Error';
import Success from '../Success';

const ErrorHandler = (props) => {
  const strings = new LocalizedStrings(stringsToLocalize);
  strings.setLanguage(props.lang);

  const [msg, setMsg] = useState(null);
  const [msgDesc, setMsgDesc] = useState(null);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [errModal, setErrModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    props.setErrorHandler(null);
  };
  useEffect(() => {
    function handleError() {
      const [cont, err, message, messageDesc, success] = returnMsg(props.msg);
      if (cont == 'undefined') {
        cont = 0;
      }
      if (props.setCont) {
        props.setCont(cont);
      }

      setMsg(message);
      setMsgDesc(messageDesc);
      if (success) {
        setSuccess(true);
        props.setStop(true);
      }
      if (!cont && !success) {
        setErrModal(true);
        setError(true);
        if (err) {
          setError(true);
          props.setStop(true);
          setShowModal(false);
        } else {
          setError(false);
          props.setStop(false);
          setShowModal(true);
        }
      } else if (!success) {
        setErrModal(false);
        setError(false);
        props.setStop(false);
        setShowModal(true);
      }
    }
    handleError();
    return () => {
      setMsg(null);
      setMsgDesc(null);
      props.setErrorHandler(null);
    };
  }, []);

  const returnMsg = (message) => {
    switch (message.toUpperCase()) {
      case 'NVN':
        return [false, 0, strings.nvn, strings.nvnDesc, 0];
      case 'NC':
        return [false, 0, strings.nc, strings.ncDesc, 0];
      case 'NG':
        return [false, 0, strings.ng, strings.ngDesc, 0];
      case 'ND':
        return [false, 0, strings.nd, strings.ndDesc, 1];
      case 'CD':
        return [true, 0, strings.cd, strings.cdDesc, 0];
      case 'WD':
        return [false, 0, strings.wd, strings.wdDesc, 0];
      case 'TA':
        return [false, 1, strings.ta, strings.taDesc, 0];
      case 'ID':
        return [false, 0, strings.id, strings.idDesc, 0];
      case 'CV':
        return [false, 0, strings.cv, strings.cvDesc, 0];
      case 'WP':
        return [false, 0, strings.wp, strings.wpDesc, 0];
      case 'TO':
        return [false, 1, strings.to, strings.toDesc, 0];
      case 'OR':
        return [true, 0, strings.or, strings.orDesc, 0];
      case 'IP':
        return [false, 0, strings.ip, strings.ipDesc, 0];
      case 'WO':
        return [false, 0, strings.wo, strings.woDesc, 0];
      case 'CAPTCHA':
        return [false, 0, strings.captcha2, strings.captchaDesc2, 0];
      default:
        return [false, 1, strings.error, strings.errDesc, 0];
    }
  };
  const modal = (
    <Modal
      show={showModal}
      onHide={handleCloseModal}
      animation={false}
      centered
    >
      <Modal.Header closeButton style={{ border: 'none' }}>
        <Modal.Title>
          <h4 style={{ fontWeight: '100' }}>
            {' '}
            <FontAwesomeIcon
              color={errModal ? c.orange : c.primary}
              icon={errModal ? faExclamationTriangle : faInfoCircle}
            ></FontAwesomeIcon>{' '}
            {msg}
          </h4>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p
          dir={strings.dir}
          style={
            strings.dir.toLowerCase() === 'rtl'
              ? { textAlign: 'right', fontSize: '2em' }
              : { textAlign: 'left', fontSize: '2em' }
          }
          dangerouslySetInnerHTML={{ __html: sanitize(msgDesc) }}
        ></p>
      </Modal.Body>
      <Modal.Footer style={{ border: 'none' }}>
        <Button variant='secondary' onClick={handleCloseModal}>
          {strings.ok}
        </Button>
      </Modal.Footer>
    </Modal>
  );

  return (
    <>
      {!error && modal}
      {error && <Error ErrorMsg={msg} ErrMsgDesc={msgDesc} />}
      {success && <Success text={msgDesc} />}
    </>
  );
};
export default ErrorHandler;
