import React from 'react';
import { Root, P } from './styles';
import sanitize from '../../utils/sanitizer';

const Intro = (props) => {
  return (
    <Root>
      <P dir={props.dir} dangerouslySetInnerHTML={{__html:sanitize(props.text)}}></P>

    </Root>
  );
};
export default Intro;
