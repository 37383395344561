import sanitizeHtml from 'sanitize-html';

const allowedTags = [
  'b',
  'i',
  'em',
  'strong',
  'a',
  'li',
  'ol',
  'ul',
  'h1',
  'h2',
  'h3',
  'br',
  'u',
  'span'
];
const Sanitizer = (html) => {
  return sanitizeHtml(html, {
    allowedTags: allowedTags,
    allowedAttributes: {
      a: ['href']
    },
    allowedSchemesByTag: {
      a: [ 'tel' ]
    }
  });
};
export default Sanitizer;
