import config from '../config';
export default {
  lang: `En`,
  begin: `Begin`,
  enterPGN: `Enter your case number`,
  enterPhone: `Enter your phone number`,
  samePhone: `Do you still have the number: `,
  heardAbout: `Where did you hear about this process (GRM)?`,
  enterOTP: `Enter the ${config.otp} digits that you received in SMS`,
  submit: `Submit`,
  stillHaveCard: `Do you still have the card?`,
  submitAnotherOTP: `Send another code`,

  stillHavePin: `Do you still have the PIN?`,
  yes: `Yes`,
  no: `No`,

  error: `Error Occured`,
  nvn: `No Validation Needed`,
  cd: `Correct Digits`,
  wd: `Wrong Digits`,
  ta: `Too Many Attempts`,
  id: `Invalid Digits`,
  cv: `Card Already Validated`,
  wp: `Wrong Case Number`,
  or: `OTP Request`,
  ip: `Invalid Phone Number`,
  wo: `Wrong OTP`,
  nc: `Not Allowed`,
  ng: `No Item Selected`,
  nd: `Already Submitted`,
  to: `Too Much Attempts`,
  captcha: `Captcha Error`,
  captchaDesc: `Please verify you are not a robot`,
  captcha2: `Captcha Error`,
  captchaDesc2: `Could not verify that you are not a robot`,
  toDesc: `You have used OTP more than 3 time please call the Call Centre on <a href="tel:+9614726111 ">04 726 111</a> Monday to Friday to make your claim`,
  ndDesc: `Your previous claim was already successfully submitted, you do not need to make another one. Please note:
  <ul>
  <li>There is no need to reenter a claim at the Call Center</li>
  <li>If you will receive Food or Basic Assistance from December 2023, you are not eligible for this process</li>
  <li>You will receive an SMS in February 2024 to inform you of the outcome of your claim</li>
  </ul>
  `,
  ngDesc: `You haven't selected anything`,
  ncDesc: `You are not allowed to use this feature`,
  ipDesc: `You have entered an invalid phone number`,
  woDesc: `You have entered a wrong code`,
  orDesc: `You will receive an SMS containing ${config.otp} digits, enter them in the text field showing`,
  wpDesc: `You have entered a wrong case number`,
  cvDesc: `Your card has already been validated`,
  idDesc: `Enter exactly ${config.digits} Digits`,
  nvnDesc: `You either have done your verification or you are not yet in the verification list`,
  cdDesc: `You have entered correct digits, continue`,
  wdDesc: `You have entered wrong digits, please make sure you are entering the last digits from left to right`,
  taDesc: `You have entered too many wrong attempts, you can't try anymore`,
  errDesc: `There have been an error, please try again`,
  ok: `Ok`,
  dir: `LTR`,
  verComp: `Verification Completed Successfully`,
  tryAgain: `Try Again`,
  remainingAttemps: `Remaining Attempts: `,
  unhcrWillContact: `UNHCR will contact you in the next few days`,
  noCard: `Thank you for completing the validation and reporting having your card missing. UNHCR will contact you in the next few days`,
  thankYou: `Your claim has been successfully logged. Please note:
  <ul>
  <li>There is no need to reenter a claim at the Call Center. Submitting more than one claim will not improve your chances of being considered</li>
  <li>Your claim is well received. Only a limited number of families will be reconsidered for assistance, priority will be given to families not receiving any type of assistance. You will be informed of the outcome of your claim by SMS latest by February 2024.</li>
  </ul>
  `,
  fail: `Unfortunately, we cannot process your claim via this link, please call the Call Centre on <a href="tel:+9614726111">04 726 111</a> Monday to Friday(8:00 am to 5:00 pm), WFP call centre at <a href="tel:1526">1526</a> from Monday to Saturday (8:00 am to 7:00 pm), to make your claim as well as update your phone number.`,
  intro: `
  You can place a claim to be reconsidered for cash assistance for food or basic assistance by UNHCR and WFP.
  <br/>
  <br/>
  All claims must be filed between October 30 and December 11, 2023. After that date, your information will be analyzed by UNHCR and WFP and only a limited number of families will be included for assistance.
  <br/>
  <br/>
  Please follow the steps to log your claim. If you already placed a claim through the call centers, you do not need to submit another one here.`,
  submitToLog: `Click on Submit to log your claim to UNCHR/WFP`,
  note: `Note: We collect this information for improvement purposes only`,
  na: `The 2023/24 GRM ended on December 11, 2023. UNHCR and WFP will inform you of the outcome of your claim by February 2024. In case of queries, please contact UNHCR <a href="tel:04726111">04 726 111</a> or WFP <a href="tel:1526">1526</a>.`,
};
