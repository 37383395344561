import config from '../config';

export const validatePhone = (phone) => {
  let regex = new RegExp(/^(961)((7(0|1|6|8|9))|((3)|(81)))\d{6}\s*$/gim);
  phone = phone.replace(/\s/g, '');
  let resPhone = '';
  if (phone.length > 8 && phone.substring(0, 3) === '961') {
    resPhone = phone;
  } else {
    if (phone.substring(0, 1) === '0') {
      phone = phone.substring(1);
    }
    resPhone = '961' + phone;
  }
  if (!regex.test(resPhone)) {
    return false;
  }
  return resPhone;
};
export const validateDigits = (digits) => {
  digits = digits.replace(/\s/g, '');
  if (digits.length != config.digits) {
    return false;
  }
  var reg = new RegExp(/^\d+$/);
  return reg.test(digits);
};
export const validatePGN = (pgn) => {
  // const regex = new RegExp(/^(LEB|[1-9]\d{2})-?\d{2}C?\d{5}$/gim);
  pgn = pgn.toUpperCase().replace(/\s/g, '').replace('/', '').split('C-');
  let PGNs = [
    pgn.join('C').replaceAll('_', ''),
    pgn.join('-').replaceAll('_', ''),
  ];
  // const regex = new RegExp(/^([a-z1-9]{3})-?\d{2}(C|\-){1}(\d{5})$/gi);
  // console.log(PGNs);
  // pgn = pgn.replace(/\s/g, '');
  let regex0 = new RegExp(/^([a-z0-9]{3})-?(\d{2})C?(\d{5})$/i);
  let regex1 = new RegExp(/^([a-z0-9]{3})-?(\d{2})-?(\d{5})$/i);
  let regex2 = new RegExp(/^([a-z0-9]{3})-?(\d{2})-?(\d{7})$/i);

  // console.log(PGNs[0], regex.test(PGNs[0].toString()));
  // console.log(PGNs[1], regex.test(PGNs[1].toString()));

  if (!regex0.test(PGNs[0]) && !regex1.test(PGNs[1]) && !regex2.test(PGNs[1])) {
    return false;
  }
  return PGNs[0].toUpperCase().replace('C', '').replace('-', '');

  // return pgn.toLowerCase().replace('-', '').replace('c', '').trim();
};
