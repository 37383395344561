import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

import { Root, Header, P } from './styles';
import c from '../../utils/constants';
import sanitize from '../../utils/sanitizer';

const Fail = (props) => {
  return (
    <Root>
      <Header>
        <FontAwesomeIcon color={c.orange} icon={faExclamationTriangle} />
      </Header>
      <P dangerouslySetInnerHTML={{__html: sanitize(props.html)}}>{props.text}</P>
    </Root>
  );
};
export default Fail;
